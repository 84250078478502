@use '@restream/styles/scss/media';

.root {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

.toastButton {
  color: inherit;
  background: transparent;

  padding: 0;
  border: none;
  text-decoration: underline;
  cursor: pointer;
}
