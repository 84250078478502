@use '@restream/styles/scss/tokens';

.root {
  position: relative;
  background: tokens.$color-white;
  // TODO(color): use token
  color: #151b26;
  padding: 15px 40px 15px 20px;
  border: tokens.$border-width solid tokens.$color-white;
  border-radius: tokens.$border-radius-200;

  font-size: tokens.$font-size-body-sm;
  line-height: tokens.$font-line-height-body-sm;
}

.postedAt {
  color: #5e5e5e;
  margin-bottom: 8px;
}

.buttonClose {
  position: absolute;
  top: 8px;
  right: 8px;
}

.title {
  font-weight: 700;
  margin-bottom: 8px;
}

.text {
  margin-bottom: 12px;
}
