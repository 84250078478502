.root {
  background: white;
  color: black;

  &:hover:not(&.disabled) {
    background: white;
    opacity: 0.8;
    color: black;
  }
}
