@use 'sass:math';
@use '@restream/styles/scss/tokens';
@import '../../styles/mixins';

$size: 32px;
$itemsPerRow: 5;

$blueBgColor: #253858;
$darkBlueBgColor: #172b4d;
$grayBgColor: #42526e;
$lightGrayBgColor: #6b778c;

.root {
  @include button-reset;

  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  margin-right: 8px;
  margin-bottom: 0;
  opacity: 1;
  padding: 2px;
  border-radius: tokens.$border-radius-200;

  transition: opacity 200ms ease;

  &.blue {
    background-color: $blueBgColor;

    .channelIcon {
      box-shadow: 0 0 0 2px $blueBgColor;
    }
  }

  &.darkBlue {
    background-color: $darkBlueBgColor;

    .channelIcon {
      box-shadow: 0 0 0 2px $darkBlueBgColor;
    }
  }

  &.gray {
    background-color: $grayBgColor;

    .channelIcon {
      box-shadow: 0 0 0 2px $grayBgColor;
    }
  }

  &.lightGray {
    background-color: $lightGrayBgColor;

    .channelIcon {
      box-shadow: 0 0 0 2px $lightGrayBgColor;
    }
  }

  &:hover {
    opacity: 0.8;
  }

  &.error {
    border-radius: tokens.$border-radius-200;
    box-shadow: 0 0 0 2px #de350b;
  }

  .destinationsContainer {
    display: flex;
    align-items: center;
  }

  .channelIcon {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    .channelIconWrapper {
      height: $size;
      width: $size;
      border: none;
      border-radius: tokens.$border-radius-150;
    }

    .platformIconWrapperClassName {
      height: 20px;
      width: 20px;
      bottom: -2px;
      right: -2px;
    }
  }

  .channelIcon,
  .placeholderIcon {
    height: $size;
    width: $size;
    border-radius: tokens.$border-radius-150;

    font-size: 12px;
    line-height: 20px;

    .placeholderIconWrapper {
      height: $size;
      width: $size;
    }
  }

  @for $i from 1 through $itemsPerRow {
    .channelIcon:nth-child(#{$i}) {
      z-index: $itemsPerRow - $i + 1;

      @if $i >= 2 {
        margin-left: math.div($size, -3);
      }
    }
  }
}
