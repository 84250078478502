@use '@restream/styles/scss/media';
@use '@restream/styles/scss/tokens';

$fg-color--darken-2: rgba(255, 255, 255, 0.5);
$gap: 16px;

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  @include media.xs {
    padding-top: 0;
  }
}

.hiddenOnMobile {
  @include media.xs {
    display: none;
  }
}

.isStudioDrafts {
  max-width: 840px !important;
}

.upgradeLinkRoot {
  min-height: unset;
  background: #5243aa;
  border-radius: tokens.$border-radius-200;
  padding: 4px 16px;
  width: unset;

  @include media.sm {
    width: 100%;
  }
}

.upgradeLinkContainer {
  width: unset;

  @include media.sm {
    width: 100%;
  }
}

.flashSale {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  position: absolute;
  top: 0;
  min-height: 36px;
  z-index: 99;

  @include media.xs {
    display: none;
  }
}

.container {
  display: flex;
  flex-direction: column;

  height: 100%;
  max-width: 1020px;
  width: 100%;

  padding: 0 70px 0;
  margin: 0 auto;

  min-height: calc(
    100vh + 70px
  ); // in order to always have tabs sticked to the header regardless of the number of events (hence page height)

  @include media.xl {
    margin-top: 0;
  }

  &.isRenaissanceLobbyView {
    padding: 0 50px;

    @media (max-width: 1100px) {
      padding: 0 16px;
    }
  }

  &,
  &.isRenaissanceLobbyView {
    @include media.md {
      padding: 0 36px;
    }

    @include media.xs {
      padding: 0 16px;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin: 24px 0 12px;

  .headerTextRoot {
    margin: 0;
  }

  .headerText {
    font-weight: normal;
    color: #edf0f3;
    text-indent: -1px;
  }
}

.lobbyDraftsHeader {
  margin-bottom: 22px;
  height: 36px;
}

.eventsTabsContent {
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
  }
}

.loading-page,
.error-page {
  height: 100%;
  align-items: center;
}

.empty-page--wrap-img,
.error-page--wrap-img {
  display: flex;
  justify-content: center;

  background: url(../../../assets/img/squids/squid-stars.png) no-repeat 50% 0;
  padding: 10px;
  margin-bottom: 20px;
}

.empty-page--wrap-img .empty-page--img,
.error-page--wrap-img .error-page--img {
  max-width: 108px;
  max-height: 146px;
  margin-top: 120px;
}

.empty-page--content,
.error-page--content {
  text-align: center;
}

.empty-page--description,
.error-page--description {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #a5adba;

  margin-top: 12px;
  margin-bottom: 24px;
}

.tabsSticky {
  position: sticky;
  top: 0;
  background: #182a4e;
  z-index: 1;
}

.toastContainer {
  width: auto;
  z-index: 11; // to avoid overlapping sidebar
  left: 248px !important;
  top: 64px !important;

  @include media.md {
    left: 0 !important;
    top: 104px !important;
  }
}

.systemMessageContainer {
  margin-top: 20px;
}
