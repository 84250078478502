@import '@restream/styles/css/tokens.css';
@import '@restream/styles/css/tailwind.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

:global(.grecaptcha-badge) {
  transform: scale(0.2);
  visibility: hidden;
  display: none;
}
