@use '@restream/styles/scss/tokens';
@use '@restream/styles/scss/media';
@use 'sass:color';

.headerContainer {
  top: 0;
  z-index: 10;
  height: auto;
  margin: 8px -20px;
  position: sticky;
  background: linear-gradient(
    180deg,
    tokens.$color-gray-800 20%,
    color.adjust(tokens.$color-gray-800, $alpha: -0.5)
  );
  backdrop-filter: blur(10px);

  &:not(:empty) {
    padding: 16px 20px;
  }

  @include media.xs {
    margin: 0 -16px 8px;

    &:not(:empty) {
      padding: 16px;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  width: 100%;
  transition: opacity 0.15s ease-in-out;
}

.createEventButton {
  @include media.xs {
    span {
      display: none;
    }
  }
}

.hidden {
  opacity: 0;
}

.filterLink {
  padding: 6px 10px;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: tokens.$color-gray-300;
  border-radius: 6px;
  position: relative;
  border: 1px solid transparent;
}

.active {
  background: tokens.$color-gray-900;
  border: 1px solid tokens.$color-gray-700;
}

.filterLink::after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  background: tokens.$color-blue-500;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  opacity: 0;
}

.highlighted::after {
  opacity: 1;
}
