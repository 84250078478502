@use '@restream/styles/scss/tokens';
@import '../common';
@import '@restream/styles/scss/variables';
@import '../../scss/media';

$side-width-desktop: 280px;

.productMenuWarning {
  // TODO(color): use token
  color: #b1b1b1;
  background-color: #263858;
  font-weight: 500;
  font-size: tokens.$font-size-body-xs;
  line-height: tokens.$font-line-height-body-xs;
  border-radius: tokens.$border-radius-200;
  padding: 1px 6px;
  cursor: pointer;
  align-self: center;
  display: flex;
  align-items: center;
  justify-self: end;
  transition: opacity 0.2s ease-in-out;

  .warningEmoji {
    margin-right: 6px;
  }

  &:hover {
    opacity: 0.9;

    .warningEmoji {
      animation-name: shake;
      animation-duration: 0.4s;
      animation-iteration-count: 1;
      animation-timing-function: linear;
    }
  }
}

.productMenuSide {
  position: absolute;
  background: tokens.$color-gray-900;
  color: tokens.$color-white;
  transition: all 300ms;
  width: $side-width-desktop;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2147483003;
  //box-shadow: $box-shadow-dark;

  @include xs {
    width: 100%;
  }

  .productMenuSideContent {
    padding: $padding;
  }

  hr {
    opacity: 0.15;
  }

  a::selection {
    // TODO(color): use token
    background: $color-primary2;
    color: $color-max-light;
  }
}

.productMenuSide.hidden {
  transform: translateX(-$side-width-desktop);

  @include xs {
    transform: translateX(-100%);
  }
}

.mobileOnly {
  display: none;

  @include xs {
    display: block;
  }
}

.desktopOnly {
  display: block;

  @include xs {
    display: none;
  }
}

.productMenuSideHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @include xs {
    align-items: flex-start;
  }
}

.headerSpace {
  width: 40px;
}

.productMenuSideLogo {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  @include logo-title;
  @include logo-hover;

  margin-bottom: 10px;
  user-select: none;

  &.withAccountAvatar {
    @include xs {
      display: none;
    }
  }

  &:not(.withAccountAvatar) {
    @include xs {
      & span {
        margin-left: 0;
      }
    }
  }
}

.productMenuSideDivider {
  height: 1px;
  overflow: hidden;
  background-color: tokens.$color-gray-800;
  width: calc(100% + 20px);
  margin: 5.5px -10px;
}

.productMenuSideClose {
  display: none;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid tokens.$color-gray-500;
  cursor: pointer;
  background: transparent;
  transition: 0.2s all ease;

  &:hover:not(&:disabled) {
    opacity: 0.8;
  }

  @include xs {
    display: flex;
  }
}

.productMenuSideCross,
.productMenuSideCross:after,
.productMenuSideCross:before {
  cursor: pointer;
  display: none;
  position: relative;
  width: 18px;
  height: 18px;

  @include xs {
    display: block;
  }
}

.productMenuSideCross:after,
.productMenuSideCross:before {
  position: absolute;
  content: '';
  width: 18px;
  height: 2px;
  top: 0;
  background: tokens.$color-white;
}

.productMenuSideCross:before {
  transform: rotate(45deg);
}

.productMenuSideCross:after {
  transform: rotate(-45deg);
}

.productMenuSideInfo {
  display: flex;
  flex-wrap: wrap;
  color: tokens.$color-gray-400;
  font-size: tokens.$font-size-body-xs;
  line-height: tokens.$font-line-height-body-xs;
  margin: 20px;
  user-select: none;

  .productMenuSideFooterLink {
    margin: 5px;
    color: tokens.$color-gray-400;
    opacity: 0.5;
    white-space: nowrap;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

@mixin use-badge {
  margin-left: 6px;
  border-radius: tokens.$border-radius-200;

  padding: 3px 5px;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.productMenuSideItemBeta:after {
  content: 'beta';

  @include use-badge;

  color: tokens.$color-gray-900;
  background-color: tokens.$color-white;
}

.productMenuSideItemDeprecated:after {
  content: 'deprecated';

  @include use-badge;

  // TODO(color): use token
  color: #b1b1b1;
  background-color: #263858;
}

.userEmail {
  // TODO(color): use token
  color: #8993a4;
  font-weight: 500;
  // TODO(font): use token
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.01em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes shake {
  0% {
    transform: rotate(24deg);
  }
  20% {
    transform: rotate(-24deg);
  }
  40% {
    transform: rotate(24deg);
  }
  80% {
    transform: rotate(-24deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.footerWrapper {
  width: 100%;
  margin-bottom: 16px;
}
