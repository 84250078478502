@use '@restream/styles/scss/tokens';

.root {
  display: grid;
  grid-template-columns: auto 1fr;

  border-radius: 4px;
  background: tokens.$color-gray-700;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  transition: background-color 0.2s ease-out;
  overflow: hidden;

  &:hover {
    background-color: tokens.$color-gray-600;
  }
}

.main {
  padding: 0 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.title {
  color: tokens.$color-gray-25;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px;
}

.text {
  color: tokens.$color-gray-200;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.linkText {
  display: inline-flex;
  align-items: center;

  color: tokens.$color-blue-500;
  font-size: 12px;
  font-weight: 500;
  margin-top: 4px;

  & .icon {
    width: 14px;
    height: 14px;
    margin-left: 4px;

    & path {
      stroke-width: 2px;
    }
  }
}

.iframeWrapper {
  display: flex;
  position: relative;
}

.iframeOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  width: 100%;
  height: 100%;
}

.iframe {
  height: 92px;
  aspect-ratio: 9/16;

  overflow: hidden;
}
