.root {
  width: 100%;
}

.subtitle {
  color: #fafbfc;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.48px;
  text-transform: uppercase;
}

.subtitle.drafts {
  margin-bottom: 12px;
  position: relative;
  padding-bottom: 10px;
  padding-top: 10px;
}

.separator:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  background-color: #2b3d5c;
  bottom: 0;
  left: 0;
}

.tab {
  color: #d7dce3;

  padding: 10px 0;
  border: none;
  text-transform: capitalize;
  border-bottom: 2px solid transparent;

  transition: 0.2s all ease-in-out;

  &:first-child {
    margin-right: 16px;
  }

  &:hover {
    color: #f5f6f8;
    border-color: #f5f6f8;
  }
}

.activeTab {
  color: #f5f6f8;
  border-color: #f5f6f8;
  cursor: default;

  &:hover {
    color: #f5f6f8;
    border-color: #f5f6f8;
  }
}

.draftsListWrapper {
  margin-bottom: 32px;
}

.tabs {
  margin-bottom: 16px !important;
}

.draftsEventsList {
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.collapsedDraftsEventsList {
  margin-top: 8px;
  width: 100%;
}

.actionButton {
  margin-bottom: 12px;

  &:last-of-type {
    margin-bottom: 20px;
  }
}
