@use '@restream/styles/scss/tokens';
@use '../colors';

@property --topColor {
  syntax: '<color>';
  initial-value: #2970ff;
  inherits: false;
}

@property --bottomColor {
  syntax: '<color>';
  initial-value: #155eef;
  inherits: false;
}

.root {
  transition: background 0.2s ease;
  cursor: pointer;

  background: transparent;
  border: none;

  color: tokens.$color-gray-25;
  text-align: center;

  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;

  padding: 8px 14px;
  border-radius: 8px;

  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 6px;

  overflow: hidden;
  position: relative;
}

.root:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.variantDefault {
  background: tokens.$color-gray-600;

  &:hover:not(:disabled) {
    background: tokens.$color-gray-500;
  }
}

.variantPrimary {
  background: linear-gradient(
    180deg,
    var(--topColor) 0%,
    var(--bottomColor) 100%
  );

  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  transition:
    --topColor 0.2s,
    --bottomColor 0.4s;

  &:hover:not(:disabled) {
    --topColor: #155eef;
    --bottomColor: #2970ff;
  }

  &:focus-visible {
    box-shadow:
      0 1px 2px 0 rgba(16, 24, 40, 0.05),
      0 0 0 4px tokens.$color-blue-50;
    outline: none;
    --topColor: #155eef;
    --bottomColor: #2970ff;
  }
}

.variantOutline {
  border: 1px solid tokens.$color-blue-200;
  background: tokens.$color-blue-50;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  color: tokens.$color-blue-700;

  &:hover:not(:disabled) {
    background: tokens.$color-blue-100;
  }

  &:focus-visible {
    box-shadow:
      0 1px 2px 0 rgba(16, 24, 40, 0.05),
      0 0 0 4px tokens.$color-blue-50;
    outline: none;
  }
}

.variantSecondary {
  border: 1px solid tokens.$color-gray-300;

  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  color: tokens.$color-gray-900;

  &:hover:not(:disabled) {
    background: tokens.$color-gray-100;
  }

  &:focus-visible {
    box-shadow:
      0 1px 2px 0 rgba(16, 24, 40, 0.05),
      0 0 0 4px tokens.$color-gray-50;
    outline: none;
  }
}

.variantGhost {
  display: inline-flex;

  background: none;
  border: none;
  margin: 0;
  padding: 2px;
  border-radius: 4px;

  &:hover:not(:disabled) {
    background-color: #42526e;
  }

  &[aria-haspopup='menu'][data-state='open'] {
    background-color: #42526e;
  }
}

.variantInputGroup {
  padding: 9px;
  border: solid 1px tokens.$color-gray-300;
  color: var(--Gray-900, #091e42);

  &:focus-within {
    outline: none;
    box-shadow: inset 0 0 0 1px #286fff;
  }
}

.variantUnderscore {
  padding: 0;
  overflow: hidden;
  text-decoration: underline;

  &:hover:not(:disabled) {
    text-decoration: none;
  }
}

:global(.dark-theme) {
  .variantInputGroup {
    background-color: colors.$dark-color-input-bg;
    border-color: colors.$dark-color-border;
  }
}

:global(.transparent-theme) {
  .variantInputGroup {
    background: colors.$transparent-theme-color-input-bg;
    border-color: colors.$transparent-theme-color-border;
  }
}

.sizeDefault {
  height: 36px;
}

.sizeIcon {
  height: 20px;
  width: 20px;
}

.sizeFitContent {
  height: fit-content;
  width: fit-content;
}

.widthFull {
  width: 100%;
}
