@use '@restream/styles/scss/tokens';

.root {
  width: 100%;
  height: 250px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 12px;
  border: 1px solid tokens.$color-gray-700;

  padding: 8px 16px;
  color: tokens.$color-gray-300;
}

.title {
  color: tokens.$color-gray-300;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  max-width: 350px;
  text-wrap: pretty;

  margin-top: 16px;

  &.error {
    display: flex;
    align-items: center;
    max-width: none;

    color: tokens.$color-red-300;
    text-align: center;
  }
}

.error {
  color: tokens.$color-red-300;
}

.button {
  color: inherit;
  font-size: inherit;
  font-weight: 400;

  background: transparent;
  text-decoration: underline;
  padding: 0 4px;

  transition: all 0.3s ease-out;

  &.retry {
    color: tokens.$color-red-300;
  }

  &:hover:not(&.disabled) {
    color: inherit;
    background: transparent;
    text-decoration: none;

    &.retry {
      color: tokens.$color-red-300;
    }
  }
}

.createButton {
  margin: 16px auto;
}
