@use '@restream/styles/scss/tokens';

$streamScaleRatio: 1;

.logo {
  max-width: 150px;
}

.container {
  display: none;

  @media (min-width: 576px) {
    display: block;
  }

  z-index: 1;
  position: absolute;
  top: calc(36px * 1);
  right: calc(40px * 1);

  &:hover,
  &:focus-visible {
    .logo {
      animation: Spike 800ms ease;
    }

    .logoTriggerButton {
      opacity: 1;
      color: #fff;
      background-color: #276cf8;
      animation: Spike 800ms ease;
    }
  }
}

$logoTransition: 500ms ease;

.logoWrapper {
  position: relative;
  max-width: 150px;
  max-height: 28px;
  transform: scale(1);
  transform-origin: top right;
  outline: 0;
  pointer-events: none;
  opacity: 0;
  will-change: opacity;
  transition: opacity $logoTransition;

  .enterDone & {
    opacity: 1;
  }

  .shouldShowLogoTrigger & {
    cursor: pointer;
    pointer-events: auto;
    opacity: 1;
  }

  .shouldShowLogoTrigger &::after {
    content: '';
    position: absolute;
    top: calc(-6px * 1);
    right: calc(-6px * 1);
    left: calc(-6px * 1);
    bottom: calc(-6px * 1);
    border: calc(2px * 1) dashed transparent;
    border-radius: tokens.$border-radius-200;
    transition: border-color $logoTransition;
  }

  .shouldShowLogoTrigger:hover &::after {
    border-color: rgba(#fff, 0.9);
  }
}

@keyframes Spike {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.94);
  }

  100% {
    transform: scale(1);
  }
}
