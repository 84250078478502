@use '~@restream/styles/scss/colors';
@use '~@restream/styles/scss/outline';

.root {
  width: 20px;
  height: 20px;

  padding: 0;
  border: 0;
  border-radius: 50%;

  color: #276cf8;
  background-color: #fff;
  font-weight: 700;
  font-size: 10px;

  // Make sure it floats above other elements
  z-index: 1;

  position: absolute;
  top: -14px;
  right: -14px;

  transition: opacity 300ms ease-out, color 300ms ease-out,
    background-color 300ms ease-out;

  cursor: pointer;

  &:hover {
    opacity: 1;
    color: #fff;
    background-color: #fa3e3e;
  }

  &:focus {
    box-shadow: 0 0 1px 2px #fff;
  }

  outline: 0;
  &:focus-visible {
    opacity: 1;
    color: #fff;
    background-color: #fa3e3e;
    outline: outline.$outline;
  }
}

.icon {
  display: block;
  margin: auto;
}
